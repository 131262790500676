import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

const menuItems = [
  {
    id: 'ashwagandha',
    label: 'Ashwagandha',
    content: 'Ashwagandha'
  },
  {
    id: 'bael',
    label: 'Bael',
    content: 'Bael'
  },
  {
    id: 'Baheda',
    label: 'Baheda',
    content: 'Baheda'
  }
];

const Sidebar = () => {
  const [selectedItem, setSelectedItem] = useState(menuItems[0].id);

  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  return (
    <div>
      {menuItems.map((item) => (
        <Link
          key={item.id}
          to={`/${item.id}`}
          className={`sidebar-item ${
            selectedItem === item.id ? 'selected' : ''
          }`}
          onClick={() => handleItemClick(item.id)}
        >
          {item.label}
        </Link>
      ))}
    </div>
  );
};

const Content = () => {
  return (
    <div>
      <Switch>
        {menuItems.map((item) => (
          <Route key={item.id} path={`/${item.id}`}>
            {item.content}
          </Route>
        ))}
      </Switch>
    </div>
  );
};

const ListPlants = () => {
  return (
      <div className="container">
        <Sidebar />
        <Content />
      </div>
  );
};

export default ListPlants;
