import React from 'react';

export default function MonsoonPlantsList() {
    return (
        <>
            <h2 className='h2Heading'>Monsoon Vegetables</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Aluleaves</td>
                        <td>Amaranthus</td>
                        <td>Beetroot</td>
                        <td>BitterGourd</td>
                        <td>BottleGourd</td>
                        <td>Brinjal</td>
                        <td>Cherry Tomato</td>
                        <td>Chilli</td>
                    </tr>
                    <tr>
                        <td>Cluster Beans</td>
                        <td>Coriander</td>
                        <td>Cucumber</td>
                        <td>Gherkins</td>
                        <td>GreenMath</td>
                        <td>LadyFinger</td>
                        <td>Long Beans</td>
                        <td>Maize</td>
                    </tr>
                    <tr>
                        <td>Pak Choy</td>
                        <td>Poined Gourd</td>
                        <td>Pumpkin</td>
                        <td>Raddish</td>
                        <td>Ridge Gourd</td>
                        <td>Spinach</td>
                        <td>Sponge Gourd</td>
                        <td>Sweet Potato</td>
                       
                    </tr>
                    <tr> <td>Tomato</td>
                        <td>Yam</td>
                        <td>White Raddish</td>
                        <td>Zucchini</td></tr>

                </tbody>
            </table>

            <h2 className='h2Heading'>Monsoon Fruits</h2>
            <table>
                <tr>
                    <td>Avocado</td>
                    <td>Banana</td>
                    <td>Cherry</td>
                    <td>Coconut</td>
                    <td>Papaya</td>
                    <td>Pineapple</td>
                    <td>Pomogranate</td>
                    <td>StarFruit</td></tr>
            </table>

            <h2 className='h2Heading'>Medicinal Plants</h2>
            <table>
                <tr>
                    <td>Adulsa</td>
                    <td>Neem</td>
                    <td>Tulasi</td>
                </tr>
            </table>

            <h2 className='h2Heading'>Spices</h2>
            <table>
                <tr>
                    <td>Bayleaf</td>
                </tr>
            </table>

            <h2 className='h2Heading'>Flowers</h2>
            <table>
                <tr>
                    <td>Mehandi</td>
                </tr>
            </table>
        </>

    )
}