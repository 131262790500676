import React from 'react';

export default function SummerPlantsList() {
    return (
        <>
            <h2 className='h2Heading'>Summer Vegetables</h2>
            <table>
                {/* <thead>
                <tr>
                <th>Vegetables</th>
				<th></th>
				<th>Sowing Method</th>
				<th>Sowing Depth (inches)	</th>
                <th>Sowing Distance (inches/feet)</th>
				<th>Days to Maturity</th>
				<th>Required Temperature</th>
                </tr>
            </thead> */}
                <tbody>
                    <tr>
                        <td>Ginger</td>
                        <td>Kohlrabi</td>
                        <td>Beetroot</td>
                        <td>BitterGourd</td>
                        <td>BottleGourd</td>
                        <td>Brinjal</td>
                        <td>Leek</td>
                    </tr>
                    <tr>
                        <td>Cluster Beans</td>
                        <td>White Raddish</td>
                        <td>Cucumber</td>
                        <td>Gherkins</td>
                        <td>Zucchini</td>
                        <td>LadyFinger</td>
                        <td>Long Beans</td>
                    </tr>
                    <tr>
                        <td>Pak Choy</td>
                        <td>Pointed Gourd</td>
                        <td>Pumpkin</td>
                        <td>Ridge Gourd</td>
                        <td>Spinach</td>
                        <td>Sponge Gourd</td>
                        <td>Maize</td>
                    </tr>

                </tbody>
            </table>

            <h2 className='h2Heading'>Summer Fruits</h2>
            <table>
                <tr>
                    <td>Bor Apple</td>
                    <td>Banana</td>
                    <td>Cashewnut</td>
                    <td>Figs</td>
                    <td>Gooseberries</td>
                    <td>Guava</td>
                    <td>Jackfruit</td>
                </tr>
                <tr>
                    <td>Lemon</td>
                    <td>Mango</td>
                    <td>Mulberries</td>
                    <td>Papaya</td>
                    <td>Pineapple</td>
                    <td>Rambhutan</td>
                    <td>Sapota</td></tr>
                <tr><td>Apple</td>
                    <td>Water Melon</td></tr>
            </table>

            <h2 className='h2Heading'>Medicinal Plants</h2>
            <table>
                <tr>
                <td>Adulsa</td>
<td>Neem</td>
<td>Tulasi</td>
<td>Aloe Vera</td>
                </tr>
                </table>

                <h2 className='h2Heading'>Spices</h2>
            <table>
                <tr>
                <td>Bayleaf</td>
<td>Cinnamon</td>
                </tr>
                </table>

                <h2 className='h2Heading'>Flowers</h2>
            <table>
                <tr>
                <td>Mehandi</td>
<td>Mogra</td>
                </tr>
                </table>
        </>

    )
}