import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import './Chatbot.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import ContactDetails from './components/pages/ContactDetails';
import Saptarishi from './components/pages/Saptarishi';
import ChatbotFunc from './components/pages/ChatbotScript';
import ListPlants from './components/pages/MedicinalPlants/ListPlants';
import SummerPlantsList from './components/pages/Nurture/SummerPlants';
import MonsoonPlantsList from './components/pages/Nurture/MonsoonPlants';
function App() {
  return (
    <>
      {/* <div className="chatbot-container">
      <ChatbotFunc></ChatbotFunc>
      </div> */}
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/saptarishi' component={Saptarishi} />
          <Route path='/contactdetails' component={ContactDetails} />
          <Route path='/summerplantlist' component={SummerPlantsList} />
          <Route path='/monsoonplantlist' component={MonsoonPlantsList} />
        </Switch>
        <ListPlants></ListPlants>
      </Router>
      

    </>
  );
}

export default App;
